import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageY = () => {
  // 設定項目 ================================================================================================
  const myLine = 'や行'
  const myID = 'ya_'
  const pathName = 'term-ya';
  const termItem = [ // 改行するときは<br>を追加
  {
    Title:'約定（ヤクジョウ）',
    Content:'約定とは、FXでの売買取引の注文が成立することを指します。'
  }
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageY
